import Link from 'next/link';

export default function Custom404() {
  return (
    <div className='bg-gradient-lightblue-to-blue grid h-screen place-items-center'>
      <div className='shadow-far1 grid place-items-center gap-10 rounded-md bg-white p-12 text-center'>
        <h1 className='text-heading1 max-w-[20ch] font-semibold'>
          That page could not be found.
        </h1>
        <Link href='/' className='text-body underline underline-offset-8'>
          Return to home
        </Link>
      </div>
    </div>
  );
}
